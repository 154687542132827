import makeValidationSchema from './CargoCommodityMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React, { useEffect } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AciHighwayCargoKeys from 'src/constants/locale/key/AciHighwayCargo'
import { Divider, Grid, Typography } from '@material-ui/core'
import { useFormContext, useWatch } from "react-hook-form";
import CngSection from 'src/components/cngcomponents/CngSection'
import CollapsibleSection from 'src/components/ui/CollapsibleSection'
import HazardousMaterialEntry from './HazardousMaterialEntry'
import HazardousMaterialFormProperties from './HazardousMaterialFormProperties'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

const {
  form: {
    field: {
      CngTextField,
      CngCodeMasterAutocompleteField,
      CngUomAutocompleteField
    }
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  productDesc: "",
  quantity: "",
  quantityUOM: "",
  weight: "",
  weightUom: "",
  hsCode: "",
  marksNumber: "",
  hazardousMaterial: [],
  version: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { getValues, setValue, watch } = useFormContext();
  const hazardousMaterial = watch('hazardousMaterial')
  const marksNumber = watch('marksNumber')
  const { translate } = useTranslation(Namespace.ACI_HIGHWAY_CARGO)
  const translatedTextsObject = makeTranslatedTextsObject()

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  function makeTranslatedTextsObject() {
    let cargoCommodity = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoCommodity.TITLE
    )
    let productDesc = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoCommodity.PRODUCT_DESC
    )
    let quantity = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoCommodity.QUANTITY
    )
    let quantityUOM = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoCommodity.QUANTITY_U_O_M
    )
    let weight = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoCommodity.WEIGHT
    )
    let weightUom = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoCommodity.WEIGHT_UOM
    )
    let hsCode = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoCommodity.HS_CODE
    )
    let marksNumber = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoCommodity.MARKS_NUMBER
    )
    let hazardousMaterial = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.HazardousMaterial.TITLE
    )
    let undgCode = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.HazardousMaterial.UNDG_CODE
    )
    let undgDesc = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.HazardousMaterial.UNDG_DESC
    )
    let adminName = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.HazardousMaterial.ADMIN_NAME
    )
    let adminContact = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.HazardousMaterial.ADMIN_CONTACT
    )

    return {
      cargoCommodity,
      productDesc,
      quantity,
      quantityUOM,
      weight,
      weightUom,
      hsCode,
      marksNumber,
      hazardousMaterial,
      undgCode,
      undgDesc,
      adminName,
      adminContact
    }
  }

  function handleAddHazardousMaterial(data) {
    const hazardousMaterial = [...getValues('hazardousMaterial'), data].map(
      (material, index) => ({ ...material, _id: index + 1 })
    )

    setValue('hazardousMaterial', hazardousMaterial)
  }

  function handleDeleteHazardousMaterial(data) {
    const hazardousMaterial = [...getValues('hazardousMaterial')].filter((material) =>
      material.id ? material.id !== data.id : material._id !== data._id
    )

    setValue('hazardousMaterial', hazardousMaterial)
  }

	return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CngSection
            title='Classification &amp; weight'
            subheader={
              <>
                <Typography color="error" component="span" variant="caption">
                  *
                </Typography>
                <Typography color="textSecondary" component="span" variant="caption">
                  Mandatory fields
                </Typography>
              </>
            }
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} md={3} />
                  <Grid item xs={12} md={9}>
                    <Grid container spacing={1}>
                      <CngGridItem xs={12} shouldHide={shouldHideMap.productDesc}>
                        <CngTextField
                          required
                          name="productDesc"
                          label={translatedTextsObject.productDesc}
                          disabled={disabled}
                          inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 300 }}
                          onBlur={(e) => {
                            setValue("productDesc", e.target.value.toUpperCase(), { shouldValidate: true })
                          }}
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.quantity}>
                        <CngTextField
                          required
                          name="quantity"
                          type="number"
                          label={translatedTextsObject.quantity}
                          disabled={disabled}
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.quantityUOM}>
                        <CngUomAutocompleteField
                          required
                          name="quantityUOM"
                          label={translatedTextsObject.quantityUOM}
                          disabled={disabled} 
                          lookupProps= {{
                            label: (record) => {
                              return `${record.code};${record.descriptionEn}`
                            },
                            filters: [{
                              field: 'uomType',
                              operator: 'equal',
                              value: 'ACI'
                            }]
                          }}
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.weight}>
                        <CngTextField
                          required
                          name="weight"
                          type="number"
                          label={translatedTextsObject.weight}
                          disabled={disabled}
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.weightUom}>
                        <CngCodeMasterAutocompleteField
                          required
                          name="weightUom"
                          label={translatedTextsObject.weightUom}
                          disabled={disabled}
                          codeType='ACIHW_WEIGHT_UOM'
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.hsCode}>
                        <CngTextField
                          name="hsCode"
                          label={translatedTextsObject.hsCode}
                          inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 10 }}
                          disabled={disabled}
                          onBlur={(e) => {
                            setValue("hsCode", e.target.value.toUpperCase(), { shouldValidate: true })
                          }}
                          size='small'
                        />
                      </CngGridItem>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CollapsibleSection
                  defaultExpanded={hazardousMaterial.length > 0}
                  onExpandedChange={(expanded) => {
                    const { initialValues } = HazardousMaterialFormProperties.formikProps
                    setValue('hazardousMaterial', expanded ? [{ ...initialValues, _id: 1 }] : [])
                  }}
                  title={translatedTextsObject.hazardousMaterial}
                >
                  <Grid container spacing={2}>
                    {hazardousMaterial.map((material, index) => {
                      const isLast = hazardousMaterial.length === 1

                      return (
                        <React.Fragment key={material._id || material.id || index}>
                          <Grid item xs={12}>
                            <HazardousMaterialEntry
                              index={index}
                              isLast={isLast}
                              onAddHazardousMaterial={handleAddHazardousMaterial}
                              onDeleteHazardousMaterial={handleDeleteHazardousMaterial}
                              hazardousMaterial={material}
                              showNotification={showNotification}
                            />
                          </Grid>
                          {hazardousMaterial.length !== index + 1 && (
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </Grid>
                </CollapsibleSection>
              </Grid>
            </Grid>
          </CngSection>
        </Grid>
        <Grid item xs={12}>
          <CollapsibleSection
            defaultExpanded={marksNumber !== ""}
            title={translatedTextsObject.marksNumber}
          >
            <Grid container>
              <Grid item xs={12} md={3} />
              <Grid item xs={12} md={9}>
                <Grid container spacing={2}>
                  <CngGridItem xs={12} shouldHide={shouldHideMap.marksNumber}>
                    <CngTextField
                      name='marksNumber'
                      label={translatedTextsObject.marksNumber}
                      disabled={disabled}
                      inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 48 }}
                      onBlur={(e) => {
                        setValue(
                          'marksNumber',
                          e.target.value.toUpperCase(),
                          { shouldValidate: true }
                        )
                      }}
                      size='small'
                    />
                  </CngGridItem>
                </Grid>
              </Grid>
            </Grid>
          </CollapsibleSection>
        </Grid>
      </Grid>
    </>
	)
}

const CargoCommodityFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default CargoCommodityFormProperties
